"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stationSetting = require("@/assets/js/stationSetting");
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _registerInner = _interopRequireDefault(require("@/pages/login/register-inner.vue"));
var _registerInnerBv = _interopRequireDefault(require("@/pages/login/register-inner-bv.vue"));
//
//
//
//
//
//
var _default = {
  mixins: [],
  data() {
    return {
      is8V: false,
      defaultPage: false
    };
  },
  components: {
    RegisterInner: _registerInner.default,
    RegisterInnerBv: _registerInnerBv.default
  },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {
    // this.defaultPage = true;
    if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;